import { flags } from '_helpers/flags'

const schema = {
  endpoint: '/api/editorial_groups',
  resource: {
    definition: 'EditorialGroup-editorialGroup.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      stat: {}
    },
  },
  subresources: {
    member: {
      endpoint: '/api/editorial_group_members',
      definition: 'EditorialGroupMember-editorialGroupMember.read',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            subtitle: {},
          },
        },
        title: {
          width: 400,
          validate: ['required'],
        },
        affiliation: {
          width: 600
        },
        flag: {
          type: 'choice',
          choices: flags,
          validate: ['required'],
        },
        stat: {},
      },
    }
  }
}

export default schema
