import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    fontFamily: 'sans-serif',
    fontSize: 20,
    color: '#eee'
  }
})

export const Logo = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      BOHEMISTYKA
    </div>
  )
}
