import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import {
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
} from 'components/table'
import { Form } from 'components/form'
import { BodySkeleton, TableLoader } from 'components/table'
import { Delete } from 'components/resource/Delete'
import { Add } from './Add'
import { useEmbeddedCollectionFetch } from './_helpers/useEmbeddedCollectionFetch'
import { performOrderChange } from './_helpers/performOrderChange'
import { applyProperties } from '_helpers/applyProperties'
import { prop } from '_helpers/prop'
import { constants, reducer } from './_state'

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    paddingBottom: 10,
    borderBottom: '1px solid #bbb',
  },
  title: {
    fontSize: 18,
  },
  mass_expand: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  add_container: {
    marginLeft: 15
  },
  collection_container: {
    position: 'relative',
    overflowX: 'inherit',
    marginBottom: 20,
  },
  cell: {
    verticalAlign: 'middle',
    borderBottom: 'none',
  },
  item_container: {
    display: 'block',
  },
  empty_results: {
    textAlign: 'left',
  },
  dragged_row: {
    display: 'block',
    '& td': {
      display: 'inline-block',
      borderBottom: 'none',
    },
    zIndex: 1400,
  },
  expansion_panel_summary: {
    display: 'flex',
    alignItems: 'center',
  },
  item_title: {
    flexGrow: 3,
    marginLeft: 10,
  },
  item_icon: {
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
  },
  expand_icon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
  },
  delete_button: {
    margin: '0 5px',
    fontWeight: 'bold',
    color: theme.palette.error.main
  }
}))

export const EmbeddedCollection = ({
  endpoint,
  pid,
  parentIri,
  properties: customProperties,
  definitionSchema,
  headerTitle = null,
  panelTitle = null,
  titleAccessor,
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(reducer, endpoint, pid)

  const handleDelete = resource => {
    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      performOrderChange(state.items, oldIndex, newIndex, dispatch)
    },
    [state.items, dispatch]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    if (state.isFetching) {
      return
    }

    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  const properties = applyProperties(
    customProperties,
    definitionSchema.properties,
    'missing'
  )

  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        <div className={classes.title}>
          {headerTitle}
        </div>
        <div
          className={classes.mass_expand}
          onClick={handleExpand}
        >
          {`${expanded.switch ? 'Zwiń' : 'Rozwiń'} wszystko`}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer className={classes.collection_container}>
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={classes.dragged_row}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }

                return (
                  <ManuallyOrderableTableRow
                    index={i}
                    key={item.uuid}
                  >
                    <TableCell
                      key="item"
                      className={classes.cell}
                      style={{ width: '100%' }}
                    >
                      <ExpansionPanel
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <ExpansionPanelSummary
                          classes={{ content: classes.expansion_panel_summary }}
                        >
                          <ManualOrderDragHandle />
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={classes.item_title}
                          >
                            <span>{panelTitle ? `${panelTitle}: ` : ''}</span>
                            {titleAccessor
                              ? typeof titleAccessor === 'function'
                                ? titleAccessor(item)
                                : prop(item, titleAccessor) || 'brak tytułu'
                              : item.title || item.translations?.pl?.title || 'brak tytułu'}
                          </Typography>
                          <div className={classes.delete_button}>
                            <Delete
                              resource={item}
                              accessor={titleAccessor || 'title'}
                              disabled={state.isFetching}
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={classes.expand_icon}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: classes.item_container }}>
                          <Form
                            url={item['@id']}
                            method="PUT"
                            properties={properties}
                            resource={item}
                            width={800}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showCancelButton={false}
                            key={`${item.uuid}-${i}`}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={classes.empty_results}
                >
                  Brak rekordów
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      <div className={classes.add_container}>
        <Add
          endpoint={endpoint}
          parent={parentIri}
          dispatch={dispatch}
          disabled={state.isFetching}
        />
      </div>
    </>
  )
}

EmbeddedCollection.propTypes = {
  endpoint: PropTypes.string.isRequired,
  pid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  parentIri: PropTypes.string.isRequired,
  properties: PropTypes.object.isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  headerTitle: PropTypes.string,
  panelTitle: PropTypes.string,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
}

