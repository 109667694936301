const LANGS = process.env.REACT_APP_RESOURCE_LANGS.split(',').map(lang => lang.trim())

export const initState = ({
  properties,
  resource,
  defaultData,
  url,
  method,
  onlyPassedProperties,
}) => ({
  values: Object.assign(
    onlyPassedProperties || method === 'POST'
      ? { ...defaultData }
      : { ...defaultData, ...resource },
    ...Object.keys(properties).map(name => name === 'translations'
      ? ({
        translations: Object.assign(
          {},
          ...LANGS.map(lang => ({
            [lang]: Object.assign(
              resource?.translations?.[lang]?.['@id'] ? { '@id': resource.translations[lang]['@id'] } : {},
              ...Object.keys(properties.translations.properties).map(name => ({
              [name]:
                resource?.translations?.[lang]?.[name] !== undefined
                ? resource.translations[lang][name]
                : properties.translations.properties[name].defaultValue !== undefined
                ? properties.translations.properties[name].defaultValue
                : null,
              }))
            )
          }))
        )})
      : ({
        [name]:
          resource?.[name] !== undefined
            ? resource[name]
            : properties[name].defaultValue !== undefined
            ? properties[name].defaultValue
            : null,
      }))
  ),
  errors: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  renderError: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  isInvalid: false,
  isProcessing: false,
  isSubmitted: false,
  url:
    resource &&
    resource?.uuid &&
    url.includes(':uuid')
      ? url.replace(
          ':uuid',
          resource.uuid
        )
      : url,
})
