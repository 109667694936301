import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  switch_track: {
    backgroundColor: theme.palette.error.main,
  },
  switch_base: {
    color: theme.palette.error.main,
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#3a6e43',
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: '#3a6e43',
    },
    '&.Mui-disabled': {
      color: theme.palette.disabled,
    }
  },
}))

export const Status = ({
  resource,
  accessor = 'stat',
  disabled = false,
  onSuccess = null,
}) => {
  const [status, setStatus] = useState(prop(resource, accessor))
  const classes = useStyles()
  const handleChange = () => {
    if (disabled) {
      return
    }

    setStatus(!status)
    fetchDataHandleAuthError(
      resource['@id'],
      'PATCH',
      {
        body: JSON.stringify({
          [accessor]: !status,
        }),
      },
      () => {
        notification('success', 'Status publikacji zmieniony')
        onSuccess && onSuccess(!status)
      },
      error => {
        setStatus(status)
        notification('error', error.response.detail)
      }
    )
  }

  return (
    <Switch
      name={`${resource['@id']}-status`}
      onChange={handleChange}
      disabled={disabled}
      checked={status}
      size="small"
      color="primary"
      classes={{
        track: classes.switch_track,
        switchBase: classes.switch_base,
        colorPrimary: classes.switch_primary,
      }}
    />
  )
}

Status.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
}
