import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'

const useStyles = makeStyles({
  authors: {
    color: '#888',
    fontSize: 12
  }
})

export const AbstractArticleTitleCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource.uuid)
        )
    }
  }

  const classes = useStyles()

  return (
    <div onClick={onClick}>
      <div className={classes.authors}>
        {resource.authors}
      </div>
      {prop(resource, accessor)}
    </div>
  )
}

AbstractArticleTitleCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    authors: PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
