import React, { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { isObjectEmpty } from '_helpers/isObjectEmpty'
import { createObjectFromString } from '_helpers/createObjectFromString'
import { SubmitButton, CancelButton } from './buttons'
import { initState } from './_helpers/initState'
import { FieldError } from './_helpers/fieldError'
import { fields } from './fields'
import { constants, reducer } from './_state'

const LANGS = process.env.REACT_APP_RESOURCE_LANGS.split(',').map(lang => lang.trim())

export const useStyles = makeStyles({
  root: {
    paddingBottom: '1.5em',
  },
  title: {
    fontSize: 20,
    marginBottom: 22,
  },
  field: {
    marginBottom: '.85em',
    '& label': {
      fontSize: '1.0em',
    },
    '& p': {
      fontSize: '.9em',
    },
  },
})

export const Form = ({
  readOnly = false,
  title = null,
  url,
  method = 'PUT',
  properties,
  resource = null,
  defaultData = {},
  handleSubmit: customHandleSubmit = null,
  handleSuccess = null,
  handleSuccessAndStay = null,
  handleCancel = null,
  showSubmitAndStayButton = true,
  showCancelButton = true,
  disabled = false,
  fieldsFullWidth = false,
  width = 300,
  children = null,
  onlyPassedProperties = false,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    {
      properties,
      resource,
      defaultData,
      url,
      method,
      onlyPassedProperties
    },
    initState
  )

  const setValue = useCallback((name, value, setRenderError = true) => {
    dispatch({
      type: constants.SET_VALUE,
      payload: { name, value, setRenderError },
    })
  }, [])

  const setError = useCallback((name, error) => {
    dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  useEffect(() => {
    if (!state.isSubmitted) {
      return
    }

    dispatch({ type: constants.RENDER_ERROR })
  }, [state.isSubmitted])

  const handleSubmitButton = e => {
    e.preventDefault()
    handleSubmit(handleSuccess)
  }
  const handleSubmitAndStayButton = e => {
    e.preventDefault()
    handleSubmit(handleSuccessAndStay)
  }

  const handleSubmit = handleSuccess => {
    if (readOnly) {
      return
    }

    dispatch({ type: constants.SUBMIT })

    if (state.isInvalid) {
      return
    }

    if (customHandleSubmit) {
      customHandleSubmit(state.values)

      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(state.values) },
      response => {
        dispatch({
          type: constants.SUCCESS,
          payload: {
            method,
            resource: onlyPassedProperties
              ? Object.assign(
                {},
                ...Object.keys(properties).map(name => name === 'translations'
                  ? ({
                    translations: Object.assign(
                      {},
                      ...LANGS.map(lang => ({
                        [lang]: Object.assign(
                          { '@id': response.translations[lang]['@id'] },
                          ...Object.keys(properties.translations.properties).map(name => ({
                            [name]: response.translations[lang][name]
                          }))
                        )
                      }))
                    )})
                  : ({ [name]: response[name] }))
              ) : response,
          },
        })

        notification(
          'success',
          ['PUT', 'PATCH'].includes(method)
            ? 'Rekord zaktualizowany'
            : 'Rekord utworzony',
        )

        handleSuccess && handleSuccess(response)
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'Formularz niepoprawny'
            : error.response.detail,
        )
      },
      {}
    )
  }

  const classes = useStyles()

  return (
    <div>
      {title && <div className={classes.title}>{title}</div>}
      {state.isProcessing && <Loader marginBottom={25} />}
      <form className={classes.root} style={{ width }}>
        {children &&
          children({
            disabled: state.isProcessing,
            submitted: state.isSubmitted,
            setValue,
            setError,
          })}
        {Object.keys(properties).map(name => {
          if (
            typeof properties[name].type === 'string' &&
            !fields[properties[name].type]
          ) {
            throw new FieldError(properties[name].type)
          }

          const FieldComponent =
            typeof properties[name].type === 'string'
              ? fields[properties[name].type]
              : properties[name].type

          const {
            type,
            description,
            hint,
            validate,
            additionalProperties,
            disabled: fieldDisabled,
            ...rest
          } = properties[name]

          return (
            <div key={name} className={classes.field}>
              <FieldComponent
                uuid={state.values.uuid}
                formUrl={state.url}
                formMethod={method}
                name={name}
                type={type}
                label={description}
                hint={hint}
                initialValue={
                  resource?.[name] !== undefined
                    ? resource[name]
                    : properties[name].defaultValue !== undefined
                    ? properties[name].defaultValue
                    : null
                }
                value={state.values[name]}
                error={state.errors[name]}
                renderError={state.renderError[name]}
                disabled={
                  readOnly || fieldDisabled || disabled || state.isProcessing
                }
                validators={validate}
                setValue={setValue}
                setError={setError}
                fullWidth={fieldsFullWidth}
                formWidth={width}
                definitionRef={additionalProperties?.$ref}
                {...rest}
              />
            </div>
          )
        })}
        {!readOnly && (
          <>
            <SubmitButton
              handleSubmit={handleSubmitButton}
              disabled={
                disabled ||
                state.isProcessing ||
                (state.isSubmitted && state.isInvalid)
              }
              classes={{ submit: classes.submit }}
            />
            {showSubmitAndStayButton && (
              <SubmitButton
                title="Zapisz i pozostań"
                handleSubmit={handleSubmitAndStayButton}
                disabled={
                  disabled ||
                  state.isProcessing ||
                  (state.isSubmitted && state.isInvalid)
                }
                classes={{ submit: classes.submit }}
              />
            )}
            {showCancelButton && (
              <CancelButton
                handleCancel={handleCancel}
                disabled={
                  disabled ||
                  state.isProcessing ||
                  (state.isSubmitted && state.isInvalid)
                }
                classes={{ submit: classes.submit }}
              />
            )}
          </>
        )}
      </form>
    </div>
  )
}

Form.propTypes = {
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  properties: PropTypes.object.isRequired,
  resource: PropTypes.object,
  defaultData: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleSuccess: PropTypes.func,
  handleSuccessAndStay: PropTypes.func,
  handleCancel: PropTypes.func,
  showSubmitAndStayButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  disabled: PropTypes.bool,
  fieldsFullWidth: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.func,
  onlyPassedProperties: PropTypes.bool
}
