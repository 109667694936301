import { Button } from '@material-ui/core'
import { styled } from '@material-ui/styles'

export const GreenButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#3a6e43'),
  backgroundColor: '#3a6e43',
  '&:hover': {
    backgroundColor: '#24452a',
  },
}))
