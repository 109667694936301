import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from '@material-ui/core'
import { makeStyles, fade } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import {
  BodySkeleton,
  TableLoader,
  TableReset,
  FilterBar,
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
  OrderBy,
  PaginationActions,
} from '.'
import { DefaultCell } from 'components/table/cells/DefaultCell'
import {
  prepareColumns,
  prepareFilters,
  prepareSorters,
  handleSorter as deafultHandleSorter,
  performManualOrderChange,
  addCustomFilters,
  useCollectionFetch,
} from './_helpers'
import { applyProperties } from '_helpers/applyProperties'
import { isObjectEmpty } from '_helpers/isObjectEmpty'
import { constants, reducer } from './_state'

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#fafbfc',
    },
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
  row_as_link: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
  empty_results: {
    textAlign: 'center',
  },
  dragged_row: {
    display: 'block',
    background: '#eee',
    '& td': {
      borderBottom: 'none',
    },
  },
  handle_column: {
    display: 'flex',
    alignItems: 'center',
  },
  table_head: {
    backgroundColor: theme.palette.primary.main,
    "& .MuiTableCell-head": {
      color: '#eee',
    },
  },
}))

export const CollectionTable = ({
  schema,
  definitionSchema,
  endpoint,
  customResourceSchema = {},
  columns,
  defaultSorters,
  defaultFilters,
  customFilters = [],
  isRowLinkable = false,
  isNumerationReverse = false,
  filterable = true,
  sortable = true,
  orderable = false,
  storeCollectionId = null,
  perPage = 10,
  editPath = null,
  children = null,
  ...rest
}) => {
  const history = useHistory()

  const preparedColumns = useMemo(
    () => prepareColumns(columns, definitionSchema.properties),
    [columns, definitionSchema.properties]
  )

  const preparedFilters = useMemo(
    () => prepareFilters(defaultFilters, preparedColumns, schema.parameters),
    [defaultFilters, preparedColumns, schema.parameters]
  )

  const filters = useMemo(
    () => addCustomFilters(customFilters, preparedFilters, schema.parameters),
    [customFilters, preparedFilters, schema.parameters]
  )

  const sorters = useMemo(
    () =>
      prepareSorters(
        orderable,
        defaultSorters,
        preparedColumns,
        schema.parameters
      ),
    [orderable, defaultSorters, preparedColumns, schema.parameters]
  )

  const [state, dispatch] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    sorters,
    storeCollectionId,
    perPage
  )

  const resourceMergedProperties = useMemo(
    () =>
      applyProperties(
        definitionSchema.properties,
        customResourceSchema.properties
      ),
    [definitionSchema.properties, customResourceSchema.properties]
  )

  const handleFilters = useCallback(
    filters =>
      dispatch({ type: constants.HANDLE_COLUMN_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const handleSorter = useCallback(
    (column, order) => deafultHandleSorter(column, order, orderable, dispatch),
    [dispatch, orderable]
  )

  const handleManualOrderSorter = useCallback(
    (column, order) =>
      dispatch({
        type: constants.HANDLE_MANUAL_ORDER_SORTER,
        payload: { order },
      }),
    [dispatch]
  )

  const handleManualOrderChange = useCallback(
    ({ oldIndex, newIndex }, e) => {
      if (oldIndex === newIndex) {
        return
      }

      performManualOrderChange(
        state.data.items,
        state.data.selected,
        oldIndex,
        newIndex,
        dispatch,
      )
    },
    [
      state.data.selected,
      state.data.items,
      dispatch,
    ]
  )

  const handleReset = useCallback(() => {
    dispatch({ type: constants.RESET })
  }, [dispatch])

  const setPage = useCallback(
    (e, page) =>
      dispatch({ type: constants.SET_PAGE, payload: { page: page + 1 } }),
    [dispatch]
  )

  const setPerPage = useCallback(
    e => {
      const perPage = e.target.value
      dispatch({ type: constants.SET_PER_PAGE, payload: { perPage } })
    },
    [dispatch]
  )

  const setSorters = useCallback(
    sorters => dispatch({ type: constants.SET_SORTERS, payload: { sorters } }),
    [dispatch]
  )

  const setFilters = useCallback(
    filters => dispatch({ type: constants.SET_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const classes = useStyles()

  const TableBodyComponent =
    orderable && state.config.sorters.ord.order
      ? ManuallyOrderableTableBody
      : TableBody

  const tableBodyComponentProps =
    orderable && state.config.sorters.ord.order
      ? {
          onSortEnd: handleManualOrderChange,
          helperClass: classes.dragged_row,
          useDragHandle: true,
        }
      : {}

  const TableRowComponent =
    orderable && state.config.sorters.ord.order
      ? ManuallyOrderableTableRow
      : TableRow

  const columnsAmount = preparedColumns.length + 1 + (orderable && 1)

  return (
    <TableContainer className={classes.container}>
      <TableLoader show={!state.init && state.isFetching} />
      <div className={classes.toolbar}>
        <TableReset
          disabled={state.isInit || state.isFetching}
          handleReset={handleReset}
        />
        {filterable && !isObjectEmpty(filters) && (
          <FilterBar
            filters={state.config.filters}
            disabled={state.isInit || state.isFetching}
            handleFilters={handleFilters}
          />
        )}
      </div>
      {children &&
        children({
          init: state.init,
          isFetching: state.isFetching,
          sorters: state.config.sorters,
          filters: state.config.filters,
          setSorters,
          setFilters,
        })}
      <Table size="small">
        <TableHead className={classes.table_head}>
          <TableRow>
            {orderable && (
              <TableCell
                key="header-mass"
                style={{ width: '5%' }}
              />
            )}
            <TableCell
              key="header-number"
              style={{ width: '7%' }}
            >
              Nr
              {orderable &&
                !state.isInit &&
                !state.isFetching && (
                  <OrderBy
                    order={state.config.sorters.ord.order}
                    column="ord"
                    handleSort={handleManualOrderSorter}
                  />
                )}
            </TableCell>
            {preparedColumns.map((column, i) => (
              <TableCell
                key={`header-${i}`}
                style={{ width: column.width }}
              >
                {column.header}
                {!state.isInit && !state.isFetching &&
                  sortable && column.sortable &&
                  (column.sorterName || column.accessor) &&
                  state.config.sorters[column.sorterName || column.accessor] && (
                    <OrderBy
                      order={state.config.sorters[column.sorterName || column.accessor].order}
                      column={column.sorterName || column.accessor}
                      handleSort={handleSorter}
                    />
                  )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBodyComponent {...tableBodyComponentProps}>
          {state.init ? (
            <BodySkeleton rows={state.config.perPage} columns={columnsAmount} />
          ) : state.data.items.length > 0 ? (
            state.data.items.map((item, i) => {
              const rowAsLink =
                typeof isRowLinkable === 'boolean'
                  ? isRowLinkable
                  : isRowLinkable(item)

              const handleRowClick = e => {
                if (rowAsLink && e.target.tagName === 'TD') {
                  editPath &&
                    history.push(
                      editPath.replace(
                        ':id',
                        item.uuid
                      )
                    )
                }
              }

              return (
                <TableRowComponent
                  onClick={handleRowClick}
                  className={clsx(classes.row, rowAsLink && classes.row_as_link)}
                  index={i}
                  key={`${item.uuid}-${Date.now()}`}
                >
                  {orderable && (
                    <TableCell
                      key="header-mass"
                      style={{ width: '5%' }}
                    >
                      <div className={classes.handle_column}>
                        {orderable && state.config.sorters.ord.order && (
                          <ManualOrderDragHandle />
                        )}
                      </div>
                    </TableCell>
                  )}
                  <TableCell
                    key="column-number"
                    style={{ width: '7%' }}
                  >
                    {orderable
                      ? isNumerationReverse
                        ? state.data.ord === 'asc'
                          ? (state.data.page - 1) * state.data.perPage + i + 1
                          : state.data.totalItems -
                            ((state.data.page - 1) * state.data.perPage + i)
                        : state.data.ord === 'asc'
                          ? state.data.totalItems -
                            ((state.data.page - 1) * state.data.perPage + i)
                          : (state.data.page - 1) * state.data.perPage + i + 1
                      : (state.data.page - 1) * state.data.perPage + i + 1}
                  </TableCell>
                  {preparedColumns.map((column, j) => {
                    const CellComponent = column.Cell || DefaultCell

                    return (
                      <TableCell
                        key={`column-${j}`}
                        style={{ width: column.width }}
                      >
                        <CellComponent
                          resource={item}
                          accessor={column.accessor}
                          propertySchema={resourceMergedProperties?.[column.accessor]}
                          tableState={state}
                          tableStateDispatch={dispatch}
                          isRowLinkable={rowAsLink}
                          editPath={editPath}
                          orderable={orderable}
                          {...rest}
                        />
                      </TableCell>
                    )
                  })}
                </TableRowComponent>
              )
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={columnsAmount}
                className={classes.empty_results}
              >
                Brak rekordów
              </TableCell>
            </TableRow>
          )}
        </TableBodyComponent>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={state.init ? 0 : state.data.page - 1}
              count={state.data.totalItems}
              onChangePage={setPage}
              onChangeRowsPerPage={setPerPage}
              rowsPerPage={state.data.perPage}
              rowsPerPageOptions={[10, 20, 50, 100]}
              ActionsComponent={PaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

CollectionTable.propTypes = {
  schema: PropTypes.shape({
    parameters: PropTypes.array,
  }).isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      sortable: PropTypes.bool,
      filterable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      Filter: PropTypes.elementType,
      Cell: PropTypes.elementType,
    })
  ),
  defaultSorters: PropTypes.objectOf(PropTypes.oneOf(['asc', 'desc'])),
  defaultFilters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ])
  ),
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  isRowLinkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isNumerationReverse: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  orderable: PropTypes.bool,
  storeCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  perPage: PropTypes.number,
  children: PropTypes.func,
  editPath: PropTypes.string,
}
