import fileSchema from './file'

const schema = {
  endpoint: '/api/obituaries',
  resource: {
    definition: 'Obituary-obituary.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            width: 500
          },
        }
      },
      file: {
        type: 'file',
        description: 'Plik pdf',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      stat: {}
    },
  },
}

export default schema
