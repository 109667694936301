import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { Close } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Loader } from 'components/Loader'
import { GreenButton } from 'components/GreenButton'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  delete: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    color: theme.palette.disabled,
  },
  confirm_text: {
    marginTop: 12,
  },
}))

const styles = () => ({
  root: {
    minHeight: 56,
  },
  close_button: {
    position: 'absolute',
    right: 8,
    top: 4,
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.close_button}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const Delete = ({
  resource,
  accessor = 'title',
  disabled = false,
  onSuccess = null
}) => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleDelete = () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      resource['@id'],
      'DELETE',
      {},
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('success', 'Rekord usunięty')

        onSuccess && onSuccess(resource)
      },
      error => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('error', error.response.detail)
      }
    )
  }

  const classes = useStyles()

  return (
    <>
      {disabled ? (
        <span className={classes.disabled}>
          Usuń
        </span>
      ) : (
        <>
          <div className={classes.text} onClick={handleConfirmToggle}>
            Usuń
          </div>
          <Dialog open={state.isConfirmOpen} fullWidth={true} maxWidth="sm">
            <DialogTitle onClose={handleConfirmToggle}>
              Usuń
            </DialogTitle>
            <DialogContent>
              {state.isFetching && <Loader align="center" marginBottom={15} />}
              <DialogContentText className={classes.confirm_text}>
                Czy napewno chcesz usunąć
                {` "${
                  accessor
                    ? typeof accessor === 'function'
                      ? accessor(resource)
                      : prop(resource, accessor) || 'brak tytułu'
                    : 'brak tytułu'
                }"?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmToggle}
                disabled={state.isFetching}
                autoFocus
                variant="outlined"
              >
                Anuluj
              </Button>
              <GreenButton
                onClick={handleDelete}
                disabled={state.isFetching}
                variant="contained"
              >
                Potwierdź
              </GreenButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

Delete.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
}
