const schema = {
  endpoint: '/api/pages',
  resource: {
    definition: 'Page-page.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            width: 400
          },
          subtitle: {
            width: 500
          },
          text: {
            type: 'tinymce',
          },
          slug: {
            type: 'slug',
            routes: {
              pl: '/:slug',
              en: '/en/:slug',
              cz: '/cz/:slug'
            },
            hint: 'Automatycznie generowany z tytułu',
            width: 300
          },
        }
      },
      idName: {
        hint: 'Wypełnia tylko programista (służy to identyfikacji strony)'
      },
      stat: {}
    },
  },
}

export default schema
