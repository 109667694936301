import { flags } from '_helpers/flags'

const schema = {
  endpoint: '/api/reviewers',
  resource: {
    definition: 'Reviewer-reviewer.read',
    properties: {
      title: {
        width: 400,
        validate: ['required'],
      },
      affiliation: {
        width: 600
      },
      flag: {
        type: 'choice',
        choices: flags,
        validate: ['required'],
      },
      stat: {}
    },
  }
}

export default schema
