import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Tabs, Tab } from '@material-ui/core'
import { TranslationTab } from './TranslationTab'
import { getResourceLangs } from '_helpers/getResourceLangs'
import { applyProperties } from '_helpers/applyProperties'
import { getSchemaByRef } from '_helpers/getSchemaByRef'

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 35,
    borderRadius: 4,
    overflow: 'hidden'
  },
  indicator: {
    backgroundColor: '#fff'
  },
  invalid: {
    backgroundColor: theme.palette.error.main,
  },
}))

export const TranslationType = ({
  name,
  initialValue,
  value,
  error = false,
  properties,
  definitionRef = null,
  renderError = false,
  disabled = false,
  setValue,
  setError,
  formWidth = 300,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (e, tab) => {
    setActiveTab(tab)
  }

  const schema = useSelector(state => state.schema)
  const definitionSchema = useMemo(
    () => (definitionRef ? getSchemaByRef(schema, definitionRef) : schema),
    [schema, definitionRef]
  )

  const mergedItemProperties = useMemo(
    () => applyProperties(properties, definitionSchema?.properties, 'missing'),
    [properties, definitionSchema]
  )

  const [state, setState] = useState({
    values: Object.assign(
      {},
      ...getResourceLangs().map(lang => ({
        [lang]: Object.assign(
          { lang },
          value?.[lang]?.['@id'] ? { '@id': value[lang]['@id'] } : {},
          ...Object.keys(mergedItemProperties).map(name => ({
            [name]:
              value?.[lang]?.[name] !== undefined ? value[lang][name] : null,
          }))
        ),
      }))
    ),
    errors: Object.assign(
      {},
      ...getResourceLangs().map(lang => ({
        [lang]: Object.assign(
          {},
          ...Object.keys(mergedItemProperties).map(name => ({ [name]: false }))
        ),
      }))
    ),
    renderError: Object.assign(
      {},
      ...getResourceLangs().map(lang => ({
        [lang]: Object.assign(
          {},
          ...Object.keys(mergedItemProperties).map(name => ({ [name]: false }))
        ),
      }))
    ),
    isInvalid: Object.assign(
      {},
      ...getResourceLangs().map(lang => ({
        [lang]: false,
      }))
    ),
    init: true,
  })

  const setTranslationValue = useCallback(
    lang => (name, value) => {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [lang]: {
            ...state.values[lang],
            [name]: value,
          },
        },
        renderError: {
          ...state.renderError,
          [lang]: {
            ...state.renderError[lang],
            [name]: true,
          },
        },
        init: false,
      }))
    },
    []
  )

  const setTranslationError = useCallback(
    lang => (name, error) => {
      setState(state => ({
        ...state,
        errors: {
          ...state.errors,
          [lang]: {
            ...state.errors[lang],
            [name]: error,
          },
        },
        isInvalid: {
          ...state.isInvalid,
          [lang]:
            Object.keys(state.errors[lang])
              .filter(key => key !== name)
              .reduce(
                (isInvalid, key) => !!state.errors[lang][key] || isInvalid,
                false
              ) || !!error,
        },
      }))
    },
    []
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(
      name,
      Object.keys(state.isInvalid).reduce(
        (isInvalid, lang) => !!state.isInvalid[lang] || isInvalid,
        false
      )
    )
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!renderError) {
      return
    }

    setState(state => ({
      ...state,
      renderError: Object.assign(
        {},
        ...getResourceLangs().map(lang => ({
          [lang]: Object.assign(
            {},
            ...Object.keys(mergedItemProperties).map(name => ({ [name]: true }))
          ),
        }))
      ),
    }))
  }, [mergedItemProperties, renderError])

  useEffect(() => {
    if (!error) {
      return
    }

    setState(state => ({
      ...state,
      errors: _.merge(state.errors, error),
    }))
  }, [error, setState])

  const classes = useStyles()

  return (
    <div className={`${classes.root} MuiPaper-elevation1`}>
      <AppBar position="static" color="secondary">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          classes={{ indicator: classes.indicator }}
        >
          {getResourceLangs().map((lang, i) => (
            <Tab
              label={lang}
              aria-controls={`panel-${i}`}
              key={`tab-${lang}`}
              className={clsx(renderError && state.isInvalid[lang] && classes.invalid)}
            />
          ))}
        </Tabs>
      </AppBar>
      {getResourceLangs().map((lang, i) => (
        <TranslationTab
          key={`panel-${lang}`}
          activeTab={activeTab}
          tabIndex={i}
          lang={lang}
          properties={mergedItemProperties}
          initialValue={initialValue?.[lang] !== undefined ? initialValue[lang] : {}}
          value={state.values[lang]}
          error={state.errors[lang]}
          renderError={state.renderError[lang]}
          globalRenderError={renderError}
          disabled={disabled}
          setTranslationValue={setTranslationValue}
          setTranslationError={setTranslationError}
          fullWidth={true}
          formWidth={formWidth}
        />
      ))}
    </div>
  )
}

TranslationType.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.object,
  value: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  properties: PropTypes.object.isRequired,
  definitionRef: PropTypes.string,
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
