import { DescriptionOutlined } from '@material-ui/icons'
import home from 'pages/Home/routes'
import pages from 'pages/Pages/routes'
import editorialGroups from 'pages/EditorialGroups/routes'
import reviewers from 'pages/Reviewers/routes'
import yearListEntries from 'pages/YearListEntries/routes'
import completeIssues from 'pages/CompleteIssues/routes'
import articles from 'pages/Articles/routes'
import phenomenonArticles from 'pages/PhenomenonArticles/routes'
import reviews from 'pages/Reviews/routes'
import chronicleArticles from 'pages/ChronicleArticles/routes'
import obituaries from 'pages/Obituaries/routes'
import config from 'pages/Config/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import admins from 'pages/Admins/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import loginEntries from 'pages/LoginEntries/routes'
import profile from 'pages/Profile/routes'

const menu = () =>
  [
    home().index,
    pages().index,
    editorialGroups().index,
    reviewers().index,
    yearListEntries().index,
    completeIssues().index,
    articles().index,
    phenomenonArticles().index,
    reviews().index,
    chronicleArticles().index,
    obituaries().index,
    config().edit,
    translatorEntries().index,
    admins().index,
    {
      title: 'Rejestry',
      icon: DescriptionOutlined,
      items: [registryEntries().index, loginEntries().index],
    },
    profile().index,
  ]

export default menu
