import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { GreenButton } from 'components/GreenButton'
import { SaveOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  submit: {
    marginTop: '1.5em',
  },
})

export const SubmitButton = ({
  title = null,
  handleSubmit,
  disabled = false,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.submit, classes.submit)}>
      <GreenButton
        type="submit"
        variant="contained"
        size="small"
        startIcon={<SaveOutlined />}
        disabled={disabled}
        onClick={handleSubmit}
      >
        {title || 'Zapisz'}
      </GreenButton>
    </div>
  )
}

SubmitButton.propTypes = {
  title: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    submit: PropTypes.string,
  }),
}
