import fileSchema from './file'

const schema = {
  endpoint: '/api/configs',
  resource: {
    definition: 'Config-config.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          file: {
            type: 'file',
            description: 'Plik pdf z informacjami o RODO',
            endpoint: fileSchema.endpoint.single,
            accept: 'application/pdf',
            validate: ['maxSize'],
          },
        },
      },
    },
  },
}

export default schema
