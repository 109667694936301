import properties from './_abstract_article'

const schema = {
  endpoint: '/api/reviews',
  resource: {
    definition: 'Review-review.read',
    properties,
  }
}

export default schema
