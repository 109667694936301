import home from 'pages/Home/routes'
import pages from 'pages/Pages/routes'
import editorialGroups from 'pages/EditorialGroups/routes'
import reviewers from 'pages/Reviewers/routes'
import yearListEntries from 'pages/YearListEntries/routes'
import completeIssues from 'pages/CompleteIssues/routes'
import articles from 'pages/Articles/routes'
import phenomenonArticles from 'pages/PhenomenonArticles/routes'
import reviews from 'pages/Reviews/routes'
import chronicleArticles from 'pages/ChronicleArticles/routes'
import obituaries from 'pages/Obituaries/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import admins from 'pages/Admins/routes'
import config from 'pages/Config/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import loginEntries from 'pages/LoginEntries/routes'
import profile from 'pages/Profile/routes'

const routes = () =>
  [
    ...getArray(home()),
    ...getArray(pages()),
    ...getArray(editorialGroups()),
    ...getArray(reviewers()),
    ...getArray(yearListEntries()),
    ...getArray(completeIssues()),
    ...getArray(articles()),
    ...getArray(phenomenonArticles()),
    ...getArray(reviews()),
    ...getArray(chronicleArticles()),
    ...getArray(obituaries()),
    ...getArray(config()),
    ...getArray(translatorEntries()),
    ...getArray(admins()),
    ...getArray(loginEntries()),
    ...getArray(registryEntries()),
    ...getArray(profile()),
  ]

const getArray = routes => Object.values(routes)

export default routes
