import { issues } from '_helpers/issues'
import fileSchema from './file'

const schema = {
  endpoint: '/api/complete_issues',
  resource: {
    definition: 'CompleteIssue-completeIssue.read',
    properties: {
      year: {
        width: 200,
        validate: ['required'],
      },
      issue: {
        type: 'choice',
        choices: issues,
        validate: ['required'],
        fullWidth: false
      },
      file: {
        type: 'file',
        description: 'Plik pdf *',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize', 'required'],
      },
      stat: {}
    }
  }
}

export default schema
