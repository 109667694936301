import { StringType } from './StringType'
import { IntegerType } from './IntegerType'
import { TinymceType } from './TinymceType'
import { PasswordRepeatType } from './PasswordRepeatType'
import { BooleanType } from './BooleanType'
import { TranslationType } from './translation/TranslationType'
import { FileType } from './file/FileType'
import { ImageType } from './ImageType'
import { ChoiceType } from './ChoiceType'
import { SlugType } from './SlugType'

export const fields = {
  string: StringType,
  integer: IntegerType,
  textarea: StringType,
  tinymce: TinymceType,
  password: StringType,
  passwordRepeat: PasswordRepeatType,
  boolean: BooleanType,
  translation: TranslationType,
  file: FileType,
  image: ImageType,
  choice: ChoiceType,
  slug: SlugType,
}
