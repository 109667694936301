import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer, Divider } from '@material-ui/core'
import { Menu } from 'components/menu'
import { Logo } from 'components/Logo'
import { MenuSkeleton } from './MenuSkeleton'
import { UserBox } from 'components/UserBox'

export const useStyles = makeStyles(theme => ({
  docked: {
    position: 'sticky',
    top: 0,
    alignSelf: 'flex-start',
  },
  drawer: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#ddd',
    zIndex: theme.zIndex.drawer + 2,
    minHeight: '100vh',
  },
  drawer_sidebar_closed: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    zIndex: theme.zIndex.drawer,
  },
  logo_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    backgroundColor: '#791b1b',
    height: 64,
    ...theme.mixins.toolbar
  },
  divider: {
    backgroundColor: '#bbb',
  },
  user_box: {
    color: '#444',
    paddingTop: 16,
    paddingBottom: 12,
    height: 53,
  },
}))

export const SideBar = ({ menu, isSidebarOpen, loading }) => {
  const classes = useStyles()

  return (
    <Drawer
      variant="permanent"
      classes={{
        docked: classes.docked,
        paper: clsx(
          classes.drawer,
          !isSidebarOpen && classes.drawer_sidebar_closed
        ),
      }}
      open={isSidebarOpen}
    >
      <div className={classes.logo_wrapper}>
        <Logo />
      </div>
      {isSidebarOpen && (
        <div className={clsx(classes.user_box)}>
          <UserBox />
        </div>
      )}
      <Divider className={classes.divider} />
      {loading ? (
        <MenuSkeleton rows={4} />
      ) : (
        <Menu items={menu} isSidebarOpen={isSidebarOpen} />
      )}
    </Drawer>
  )
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
})

SideBar.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ),
  isSidebarOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
}
