import { issues } from '_helpers/issues'
import fileSchema from './file'

const properties = {
  translations: {
    type: 'translation',
    properties: {
      additionalFileTitle: {
        description: 'Tytuł dodatkowego pliku'
      },
    },
  },
  title: {
    width: 800,
    validate: ['required'],
  },
  titleEn: {
    width: 800,
  },
  authors: {
    width: 800,
    validate: ['required'],
    hint: 'Oddziel autorów przecinkiem, jeżeli występuje więcej niż 1'
  },
  year: {
    width: 200,
    validate: ['required'],
  },
  issue: {
    type: 'choice',
    choices: issues,
    validate: ['required'],
    fullWidth: false
  },
  file: {
    type: 'file',
    description: 'Plik pdf *',
    endpoint: fileSchema.endpoint.single,
    accept: 'application/pdf',
    validate: ['maxSize', 'required'],
  },
  additionalFile: {
    type: 'file',
    description: 'Dodatkowy plik pdf',
    endpoint: fileSchema.endpoint.single,
    accept: 'application/pdf',
    validate: ['maxSize'],
  },
  stat: {}
}

export default properties
