import properties from './_abstract_article'

const schema = {
  endpoint: '/api/phenomenon_articles',
  resource: {
    definition: 'PhenomenonArticle-phenomenonArticle.read',
    properties,
  }
}

export default schema
