import React, { useState, useMemo, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  InputLabel,
  IconButton,
  Tooltip,
  FormHelperText,
} from '@material-ui/core'
import {
  CreateOutlined,
  PhotoSizeSelectLargeOutlined,
  DeleteForeverOutlined,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { UploadDialog } from './UploadDialog'
import { ThumbsDialog } from './ThumbsDialog'
import { RemoveDialog } from './RemoveDialog'
import { validate } from '_helpers/validate'

const useStyles = makeStyles(theme => ({
  label_container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  label_button: {
    cursor: 'pointer',
  },
  delete_button: {
    color: theme.palette.error.main,
  },
  disabled_button: {
    color: theme.palette.disabled,
  },
  image_preview: {
    maxWidth: 500,
    maxHeight: 300,
    marginTop: 5,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  thumbs: {
    marginTop: 15,
  },
  green_icon: {
    color: '#3a6e43'
  }
}))

export const FileType = ({
  name,
  type = 'file',
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  endpoint,
  disabled = false,
  validators,
  setValue,
  setError,
  formUrl,
  formMethod,
  uuid,
  thumbs = false,
  accept = null,
}) => {
  const [state, setState] = useState({
    isUploadDialogOpen: false,
    isThumbsDialogOpen: false,
    isRemoveDialogOpen: false,
  })

  const handleToggle = type => () => {
    const fieldName = `is${type.charAt(0).toUpperCase()}${type.slice(
      1
    )}DialogOpen`

    setState(state => ({
      ...state,
      [fieldName]: !state[fieldName],
    }))
  }

  const handleChange = value => {
    setValue(name, value, true)
    validateField(value)
  }

  const setUpdatedAt = value => {
    setValue('updatedAt', value)
  }

  const uploadValidators = useMemo(
    () => validators && validators.filter(item => item !== 'required'),
    [validators]
  )

  const validateField = useCallback(
    value => {
      if (!validators || !validators.includes('required')) {
        setError(name, false)

        return
      }

      const valid = validate(['required'], value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const TooltipComponent = disabled ? 'span' : Tooltip

  const classes = useStyles()

  return (
    <>
      <div
        className={classes.label_container}
      >
        <InputLabel className={classes.label}>
          <span style={label.color && { color: label.color }}>
            {label.text || label}
            {renderError && error && (
              <FormHelperText error={true}>{error}</FormHelperText>
            )}
          </span>
        </InputLabel>
        <TooltipComponent title={value ? 'Zmień' : 'Dodaj'}>
          <IconButton
            color="secondary"
            onClick={handleToggle('upload')}
            disabled={disabled}
            className={classes.green_icon}
          >
            <CreateOutlined
              className={classes.label_button}
            />
          </IconButton>
        </TooltipComponent>
        {uuid && value && thumbs && (
          <TooltipComponent title="Kadry">
            <IconButton
              color="secondary"
              onClick={handleToggle('thumbs')}
              disabled={disabled}
              className={classes.green_icon}
            >
              <PhotoSizeSelectLargeOutlined className={classes.label_button} />
            </IconButton>
          </TooltipComponent>
        )}
        {value && (!validators || !validators.includes('required')) && (
          <TooltipComponent title="Usuń">
            <IconButton
              color="primary"
              onClick={handleToggle('remove')}
              disabled={disabled}
            >
              <DeleteForeverOutlined
                className={clsx(
                  classes.label_button,
                  classes.delete_button,
                  disabled && classes.disabled_button
                )}
              />
            </IconButton>
          </TooltipComponent>
        )}
      </div>
      <UploadDialog
        name={name}
        type={type}
        hint={hint}
        endpoint={endpoint}
        disabled={disabled}
        validators={uploadValidators}
        setValue={handleChange}
        setUpdatedAt={setUpdatedAt}
        formUrl={formUrl}
        formMethod={formMethod}
        accept={accept}
        isOpen={state.isUploadDialogOpen}
        handleToggle={handleToggle('upload')}
      />
      {value &&
        (type === 'image' ? (
          <img
            src={`${process.env.REACT_APP_API_ENTRYPOINT}/${value.url}`}
            className={classes.image_preview}
            alt="preview"
          />
        ) : (
          <a
            className={classes.link}
            href={`${process.env.REACT_APP_API_ENTRYPOINT}/${value.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value.originalName}
          </a>
        ))}
      {uuid &&
        value &&
        thumbs && (
          <ThumbsDialog
            endpoint={thumbs.endpoint}
            pid={uuid}
            originalPhotoUrl={value && value.url}
            disabled={disabled}
            isOpen={state.isThumbsDialogOpen}
            handleToggle={handleToggle('thumbs')}
            names={thumbs.names}
          />
        )}
      {value && (!validators || !validators.includes('required')) && (
        <RemoveDialog
          name={name}
          setValue={handleChange}
          isOpen={state.isRemoveDialogOpen}
          handleToggle={handleToggle('remove')}
          disabled={disabled}
        />
      )}
    </>
  )
}

FileType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'image']),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  value: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      endpoint: PropTypes.string.isRequired,
      names: PropTypes.object.isRequired,
    }),
  ]),
  accept: PropTypes.string,
}
