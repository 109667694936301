export const flags = {
  pl: 'Polska',
  cz: 'Czechy',
  gb: 'Wielka Brytania',
  bg: 'Bułgaria',
  sk: 'Słowacja',
  au: 'Austria',
  ua: 'Ukraina',
  it: 'Włochy',
  fr: 'Francja',
  de: 'Niemcy',
  ru: 'Rosja'
}
