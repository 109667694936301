import { makeStyles, fade } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    width: 280,
    marginTop: '10em',
  },
  logo: {
    height: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#791b1b',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: 8,
    '& label': {
      fontSize: 14
    }
  },
  form_body: {
    padding: '0 .65em 0 .65em',
  },
  input: {
    marginTop: '.4em',
    '& input': {
      fontSize: 15
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 0, 0),
    color: '#fff',
    backgroundColor: '#791b1b',
    '&:hover': {
      backgroundColor: fade('#791b1b', 0.85),
    },
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  loader: {
    height: 24,
    animationName: '$spin',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  lower_button: {
    marginTop: 20,
    fontSize: 12,
    color: '#555',
    backgroundColor: '#ddd',
    '&:hover': {
      color: '#555',
      backgroundColor: '#eee',
    }
  },
  dialog_title: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 10,
    fontSize: 15,
    color: '#333'
  },
  success_message: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    padding: '0 2em 2em 2em',
    color: theme.palette.success.main,
  },
  title: {
    marginTop: 12,
    textAlign: 'center'
  },
  password_error: {
    marginBottom: '1.5em',
    textAlign: 'center',
    color: theme.palette.error.main,
  },
}))
