import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { FieldError } from 'components/form/_helpers/fieldError'
import { fields } from 'components/form/fields'

export const useStyles = makeStyles({
  root: {
    paddingTop: 14,
    paddingBottom: 10,
    paddingLeft: 18,
    paddingRight: 18,
    maxWidth: 800
  },
  field: {
    marginBottom: '1.5em',
    '& label': {
      fontSize: '1.0em',
    },
    '& p': {
      fontSize: '.9em',
    },
  },
})

export const TranslationTab = ({
  activeTab,
  tabIndex,
  lang,
  properties,
  initialValue,
  value,
  error,
  renderError,
  globalRenderError,
  disabled = false,
  setTranslationValue,
  setTranslationError,
  fullWidth = false,
  formWidth = 300,
}) => {
  const classes = useStyles()

  const setValue = useMemo(() => setTranslationValue(lang), [
    setTranslationValue,
    lang,
  ])

  const setError = useMemo(() => setTranslationError(lang), [
    setTranslationError,
    lang,
  ])

  return (
    <div
      role="tabpanel"
      hidden={activeTab !== tabIndex}
      id={`panel-${tabIndex}`}
      aria-labelledby={`tab-${tabIndex}`}
      className={classes.root}
    >
      {Object.keys(properties).map(name => {
        if (
          typeof properties[name].type === 'string' &&
          !fields[properties[name].type]
        ) {
          throw new FieldError(properties[name].type)
        }

        const FieldComponent =
          typeof properties[name].type === 'string'
            ? fields[properties[name].type]
            : properties[name].type

        const { type, description, hint, validate, ...rest } = properties[name]

        return (
          <div key={name} className={classes.field}>
            <FieldComponent
              name={name}
              type={type}
              label={description}
              hint={hint}
              initialValue={
                initialValue?.[name] !== undefined ? initialValue[name] : null
              }
              value={value[name]}
              error={error[name]}
              renderError={globalRenderError && renderError[name]}
              disabled={disabled}
              validators={validate}
              setValue={setValue}
              setError={setError}
              fullWidth={fullWidth}
              formWidth={formWidth}
              formUrl="TRANSLATION"
              formMethod="TRANSLATION"
              translationLang={lang}
              {...rest}
            />
          </div>
        )
      })}
    </div>
  )
}

TranslationTab.propTypes = {
  activeTab: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  properties: PropTypes.object.isRequired,
  initialValue: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  renderError: PropTypes.object.isRequired,
  globalRenderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  setTranslationValue: PropTypes.func.isRequired,
  setTranslationError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
