import fileSchema from './file'

const schema = {
  endpoint: '/api/year_list_entries',
  resource: {
    definition: 'YearListEntry-yearListEntry.read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            width: 500
          },
        }
      },
      file: {
        type: 'file',
        description: 'Plik pdf *',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize', 'required'],
      },
      stat: {}
    },
  },
}

export default schema
